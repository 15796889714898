<template>
  <div class="editCourse mt-5">
    <div class="basic">
      <div class="container p-4 editCourse__container mb-5">
        <div class="row">
          <div class="col-12 col-md-8">
            <ValidationObserver v-slot="{ handleSubmit }">
              <form class="row login__formContent" @submit.prevent="handleSubmit(save)">
                <div class="col-12 col-md-7">
                  <label for="Username" class="form-label text-start d-inline-block w-100">課程標題</label>
                  <ValidationProvider rules='required' v-slot='{ errors , classes }'>
                    <div :class="classes">
                      <input
                        type="text"
                        name="username"
                        v-model="course.name"
                        class="form-control mb-1"
                        id="Username"
                        required
                        @change="change = true"
                      >
                      <span class="text-danger mt-1">{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-12 col-md-5">
                  <label class="form-label text-start d-inline-block w-100">課程分類</label>
                  <select class="form-select" aria-label="courses__filter" v-model="course.categoryId"
                  >
                    <option disabled value>請選擇分類</option>
                    <option :value="item.id" v-for="item in categories" :key="item.id">{{ item.name }}</option>
                  </select>
                  <span class="invalid text-danger mt-1" v-if="categoryError">{{ categoryError }}</span>
                </div>
                <div class="col-12 mt-3">
                  <label for="teacher" class="form-label text-start d-inline-block w-100">授課教師</label>
                  <ValidationProvider rules='required' v-slot='{ errors , classes }'>
                    <div :class="classes">
                      <input
                        type="text"
                        name="teacher"
                        v-model="course.authorName"
                        class="form-control mb-1"
                        id="teacher"
                        required
                        @change="change = true"
                      >
                      <span class="text-danger mt-1">{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-12 mt-3">
                  <label for="desc" class="form-label text-start d-inline-block w-50">課程描述
                    <small style="font-size:.8rem;">（字數限制為4000字內）</small>
                  </label>
                  <span class="editCourse__addLink d-inline-block w-50 text-end" data-bs-toggle="modal" data-bs-target="#linkModal"><i class="bi bi-link-45deg"></i>連結</span>
                  <ValidationProvider rules='required' v-slot='{ errors, classes }'>
                    <div :class="classes">
                      <textarea
                        v-model="course.detail"
                        rows="8"
                        name="desc"
                        class="form-control mb-1"
                        required>
                      </textarea>
                      <span class="text-danger mt-1">{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-12 mt-3">
                  <label for="tags" class="form-label text-start d-inline-block w-100">標籤</label>
                  <div class="row">
                    <div class="col-9 col-md-10">
                      <input
                        @keypress.enter.prevent="handleKeydown"
                        type="text"
                        v-model.trim="tag"
                        class="form-control mb-1"
                        @change="change = true">
                    </div>
                    <div class="col-3 col-md-2 ps-0 ps-md-2">
                      <button type="button" class="btn btn-main-outline w-100" @click.prevent="handleKeydown">新增</button>
                    </div>
                  </div>
                  <div class="d-flex justify-content-start flex-wrap" style="min-height: 40px;">
                    <div v-for="(tag, i) in tags" :key="i" class="badge rounded-pill editCourse__pill">
                      <span>#{{ tag }}</span>
                      <i class="far fa-times-circle ms-1" @click.prevent="deleteTag(i)"></i>
                    </div>
                  </div>
                </div>
                <div class="col-12 mt-3">
                  <label class="form-label text-start d-inline-block w-100">銷售模式</label>
                  <div class="row">
                    <div class="col-12">
                      <div class="d-flex justify-content-start align-items-center my-2">
                        <input type="checkbox" class="btn-check" id="b2c" value='b2c' v-model="pattern" @change="checkClick();changePattern('b2c')">
                        <label class="btn btn-main-outline me-3" for="b2c" style="width: 8rem;">B2C</label>
                        <input type="checkbox" class="btn-check" id="b2b" value="b2b" v-model="pattern" @change="checkClick();changePattern('b2b')">
                        <label class="btn btn-main-outline" for="b2b" style="width: 8rem;">B2B</label>
                      </div>
                    </div>
                  </div>
                  <p class="text-danger mt-1 text-start">{{ errorStr }}</p>
                </div>
                <div class="col-12 mt-3">
                  <div v-if="pattern.includes('b2b')" class="text-start mt-2">
                    <h3 class="mb-2">業務銷售資訊</h3>
                    <textarea class="w-100" rows="5"
                      v-model="tempContent"
                    >
                    </textarea>
                    <p class="text-danger mt-1 text-start">{{ emptyContact }}</p>
                  </div>
                </div>

                <div class="col-12 my-3 d-flex align-items-center">
                  <label for="bookcover" class="editCourse__bookcover-btn pe-2 pe-md-0 d-flex justify-content-start"><span class="btn btn-main-outline mb-2 w-100" style="max-width: 20rem;">上傳書本圖片</span></label>
                  <input type="file" name="bookcover" id="bookcover" class="d-none" ref="bookfiles" @change="getBookUploaded();change = true">
                  <img src="../assets/images/coverImg.svg" width="160" alt="" ref="bookImg" v-if="!bookImg || bookImg == 'NotSet'">
                  <img :src="bookImg" width="160" alt="" ref="bookImg" v-if="bookImg && bookImg!== 'NotSet'">
                  <input type="hidden" v-model='bookImg'>
                </div>

                <div class="col-12 my-3 d-flex align-items-center" v-if="sampleFiles.length > 0">
                  <h3>已上傳之範例檔案</h3>
                  <div class="col-2 d-flex flex-column align-items-center mb-3 position-relative" v-for="(item, i) in sampleFiles" :key="i">
                    <img src="../assets/images/file-upload.svg" alt="" width="35">
                    <div class="d-flex flex-column">
                      <p class="mb-1">{{ item.fileName }}</p>
                      <a class="mb-2 text-muted" :href="item.link" target="_blank">檔案</a>
                    </div>
                    <!-- <i class="bi bi-x-circle position-absolute editCourse__deleteFile" @click.prevent="deleteFile(item.id)"></i> -->
                  </div>
                </div>

                <button class="btn btn-main mt-3 w-50 d-none d-md-block" style="margin-left: 12px;" @click.prevent="save">儲存</button>
              </form>
            </ValidationObserver>
          </div>
          <div class="col-12 col-md-4 mt-4 mt-md-0">
            <div class="d-flex flex-column align-items-start mx-auto" style="max-width: 20rem;">
              <div
                v-for="(item, i) in testChannels"
                :key="i"
              >
                <div
                  class="my-1 courses__cover__adminChannel__str"
                  data-bs-target="#editChannelModal"
                  data-bs-toggle="modal" v-if="item.productSN">
                  <span>{{ item.salesChannelName }} : </span>
                  <span>{{ item.productSN }}</span>
                  <!-- <i class="bi bi-pen-fill ms-1"
                    data-bs-target="#editChannelModal"
                    data-bs-toggle="modal"
                  ></i> -->
                </div>
              </div>
            </div>
            <span class="btn btn-main-outline mb-3 w-100"
              data-bs-target="#editChannelModal"
              data-bs-toggle="modal"
              v-if="!showChannelEditor"
              style="max-width: 20rem;"
            >
              <span class="me-2">編輯銷售通路</span>
              <i class="bi bi-pen"></i>
            </span>
            <input type="hidden" v-model='coverImg'>
            <div class="card courses__card mx-auto"
              style="max-width: 20rem;min-height: 300px;"
            >
              <div class="courses__cover" ref="img" v-if="!coverImg || coverImg == 'NotSet'">
              </div>
              <div class="courses__cover" ref="img" v-if="coverImg && coverImg !== 'NotSet'" :style="{ backgroundImage: `url(${coverImg})`}">
              </div>
              <div class="card-body text-start py-4">
                <h5 class="card-title courses__card-title fw-bold" style="min-height: 30px;">{{ course.name }}</h5>
                <h6 class="card-title text-muted" style="min-height: 24px;">{{ course.authorName }}</h6>
                <div class="d-flex align-items-end mb-2">
                  <div class="courses__rating me-2">
                    <star-rating
                      :rating="course.rating"
                      :show-rating="false"
                      :rounded-corners="true"
                      :star-size="20"
                      :read-only="true"
                      :increment="0.5"
                      :border-width="0" />
                  </div>
                  <span class="card-subtitle text-muted d-inline-block"><small>{{ course.ratingCount }}</small> 則評價</span>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <span class="card-subtitle text-muted"><small>{{ course.numberOfPeople }}</small> 位學生</span>
                  <strong class="text-right courses__card-price">NT$ <span>{{ course.price }}</span></strong>
                </div>
              </div>
            </div>
            <label for="cover" class="w-100 mt-3"><span class="btn btn-main-outline mb-2 w-100" style="max-width: 20rem;">上傳課程封面圖片</span></label>
            <input type="file" name="cover" id="cover" class="d-none" ref="files" @change="getUploaded();change = true">
            <div class="form-check mt-4 text-start mx-auto" style="max-width: 20rem;">
              <input class="form-check-input" type="checkbox" id="setSelected" v-model="course.selected" @change="change = true">
              <label class="form-check-label" for="setSelected" style="line-height: 1.5rem;">
                設為<strong class="editCourse__strong ms-1">精選課程</strong>
              </label>
            </div>

            <div class="form-check mt-4 text-start mx-auto" style="max-width: 20rem;">
              <input class="form-check-input" type="checkbox" id="review" v-model="course.reviewed" @change="change = true">
              <label class="form-check-label" for="review" style="line-height: 1.5rem;">
                審核通過
              </label>
            </div>

            <div class="form-group mt-5 text-start mx-auto" style="max-width: 20rem;">
              <label class="form-label text-start d-inline-block w-100">教師</label>
              <select class="form-select" aria-label="courses__author" v-model="authorId">
                <option disabled value>請選擇教師</option>
                <option :value="item.id" v-for="item in teacherList" :key="item.id">{{ item.userName }}</option>
              </select>
              <!-- <span class="invalid text-danger mt-1" v-if="categoryError">{{ categoryError }}</span> -->
            </div>
          </div>
          <button class="btn btn-main mt-3 w-50 d-block d-md-none" style="margin-left: 12px;" @click.prevent="save">儲存</button>

        </div>

        <div class="modal fade" id="linkModal" tabindex="-1" aria-labelledby="linkModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click.prevent="addedLink='';addedLinkText='';linkString=''"></button>
            </div>
            <div class="modal-body d-flex flex-column text-start">
              <p class="mb-2">連結</p>
              <input type="text" class="form-control mb-2" v-model="addedLink" placeholder="https://...">
              <p class="mb-2">連結文字</p>
              <input type="text" class="form-control mb-2" v-model="addedLinkText" placeholder="網路書店">
              <button type="button" @click.prevent="linkify()" class="btn btn-primary mb-2">生成</button>
              <input type="text" class="form-control" v-model="linkString">
            </div>
          </div>
        </div>
      </div>

      </div>
      <div class="container p-4 editCourse__container mb-5">
        <div class="course__accor py-1">
          <h3 class="text-start fw-bold mb-1" style="font-size: 1.2rem;">章節</h3>
          <div class="accordion" id="accordionExample">
            <div class="accordion-item" v-for="(item, i) in chapters" :key="i">
              <h2 class="accordion-header" :id="`L${i}`">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="`#collapse${i}`" aria-expanded="false" :aria-controls="`collapse${i}`">
                  {{ item.title }}
                </button>
              </h2>
              <!-- <template v-if="i==0"> -->
                <div :id="`collapse${i}`" class="accordion-collapse collapse" :aria-labelledby="`heading${i}`" >
                <div class="accordion-body p-2">
                  <ul>
                    <li class="course__accor__list d-flex justify-content-between"
                      v-for="(sub, j) in item.chaperItem" :key="j"
                      >
                      <!-- <i v-if="sub.lock" class="fas fa-lock me-2 d-inline-block"></i>
                      <i v-if="!sub.lock" class="fas fa-play me-2 d-inline-block"></i> -->
                      <span class="title fw-bold" style="flex: 1 1 0%;text-decoration: underline;"
                        data-bs-target="#freeVideoModal" data-bs-toggle="modal" @click="videoUrl = sub.videoUrl;freeVideoTitle = sub.title;tempChapterContent = sub.content">{{ sub.title }}</span>
                      <span class="time">{{ formatTime(sub.duration.min) }} : {{ formatTime(sub.duration.sec) }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- </template> -->
              <!-- <template v-if="i!==0">
                <div :id="`collapse${item.id}`" class="accordion-collapse collapse" :aria-labelledby="`heading${item.id}`">
                <div class="accordion-body p-2">
                  <ul>
                    <li class="course__accor__list d-flex justify-content-between"
                      v-for="sub in item.subChapter" :key="sub.id"
                      >
                      <i class="far fa-circle me-2 d-inline-block"></i>
                      <span class="title" style="flex: 1 1 0%;">{{ sub.name }}</span>
                      <span class="time">{{ sub.duration }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              </template> -->
            </div>
          </div>
        </div>

      </div>
      <div class="modal fade" id="freeVideoModal" tabindex="-1" aria-labelledby="freeVideoModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header border-0 pb-0">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click.prevent="pauseVideo"></button>
            </div>
            <div class="modal-body">
              <h3 class="mb-3 text-start fw-bold"><i class="bi bi-card-heading me-1"></i>單元：{{ freeVideoTitle }}</h3>
              <div class="video__wrapper" ref="wrapper">
                <vue-vimeo-player ref="player"
                  :video-id="videoUrl.split('/')[4].split('?')[0]"
                  :video-url="`${videoUrl}?&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`"
                  :options="{ responsive: true }"
                  ></vue-vimeo-player>
              </div>
              <h3 class="my-3 text-start fw-bold"><i class="bi bi-blockquote-right me-1"></i>內容描述</h3>
              <div v-html="tempChapterContent"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="editChannelModal" tabindex="-1" aria-labelledby="editChannelModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header border-0 pb-0">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div>
                <div class="d-flex mb-3">
                  <div class="w-25">通路名稱</div>
                  <div class="w-75">產品編號</div>
                </div>
                <hr class="w-100" />
                <div class="d-flex mb-2 align-items-center" v-for="item in channels" :key="item.salesChannelId">
                  <div class="w-25">
                    {{ item.salesChannelName }}
                  </div>
                  <input
                    type="text"
                    class="form-control w-75"
                    :value="item.productSN"
                    readonly
                    v-if="tempChannel.salesChannelId !== item.salesChannelId"
                    @click.prevent="editChannel(item)"
                  >
                  <input
                    type="text"
                    class="form-control w-75"
                    v-model="tempChannel.productSN"
                    v-if="tempChannel.salesChannelId == item.salesChannelId"
                    @change="finishChannel(tempChannel)"
                  >
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">關閉</button>
              <button type="button" class="btn btn-main" data-bs-dismiss="modal" @click.prevent="updateChannelData">送出</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

// channels: [{},{}]
import StarRating from 'vue-star-rating';
import { vueVimeoPlayer } from 'vue-vimeo-player';
import { mapGetters, mapActions } from 'vuex';

const Swal = require('sweetalert2');

export default {
  name: 'EditCourse',
  components: {
    StarRating,
    vueVimeoPlayer,
  },
  data() {
    return {
      title: '',
      category: '',
      categories: [],
      chapters: [],
      desc: '',
      bookImg: '',
      coverImg: '',
      goNext: false,
      tag: '',
      tags: [],
      localCourse: {},
      isSelected: false,
      categoryError: '',
      authorId: '',
      authorName: '',
      id: '',
      editData: '',
      teacher: '',
      teacherList: [],
      change: false,
      errorStr: '',
      emptyContact: '',
      pattern: [],
      tempContent: '',
      addedLink: '',
      addedLinkText: '',
      linkString: '',
      course: {
        name: '',
        detail: '',
        prevImageUrl: '',
        prevImageUrl1: '',
        price: 0,
        categoryId: 0,
        authorId: '',
        ena: true,
        selected: true,
        authorName: '',
        rating: 4,
        ratingCount: 23,
        numberOfPeople: 33,
        hashtags: [],
        reviewed: true,
      },
      metaTitle: '編輯課程 / 課程 / 管理 - 元學堂',
      videoUrl: 'https://player.vimeo.com/video/735690297?h=2dfd01d146',
      freeVideoTitle: '',
      tempChapterContent: '',
      sampleFiles: [],
      channels: [],
      testChannels: [],
      tempChannel: {
        productSN: "",
        salesChannelId: 0,
        salesChannelName: ""
      }
    };
  },
  metaInfo: {
    title: "編輯課程 / 課程 / 管理 - 元學堂",
    meta: [
      { name: 'description', content: '博碩文化數位影音教學平台，以專業的師資教材，提供您全方位的影音學習環境，突破傳統的學習模式。' },
      {
        property: 'og:title',
        content: '編輯課程 / 課程 / 管理 - 元學堂',
      },
      {
        property: 'og:description',
        content: '博碩文化數位影音教學平台，以專業的師資教材，提供您全方位的影音學習環境，突破傳統的學習模式。',
      },
    ]
  },
  methods: {
    save() {
      const vm = this;
      vm.goNext = true;
      // if(vm.category == '') {
      //   vm.categoryError = '請選擇課程分類'
      // }
      if (this.pattern.length == 0) {
        return;
      }
      let data;
      let isB2b = false;
      let isB2c = false;
      if (this.pattern.indexOf('b2b') >= 0) {
        isB2b = true;
      } else if (this.pattern.indexOf('b2b') == -1) {
        isB2b = false;
      }
      if (this.pattern.indexOf('b2c') >= 0) {
        isB2c = true;
      } else if (this.pattern.indexOf('b2c') == -1) {
        isB2c = false;
      }
      if (this.pattern.includes('b2b') && this.tempContent == '') {
        this.emptyContact = '請填寫業務資訊';
        return;
      }
      this.emptyContact = '';

      data = {
        courseName: this.course.name,
        detail: this.course.detail,
        prevImageUrl: this.coverImg,
        prevImageUrl1: this.bookImg,
        price: this.course.price,
        categoryId: this.course.categoryId,
        authorId: this.authorId,
        ena: true,
        courseHashtag: this.tags,
        selected: this.course.selected,
        isB2c,
        isB2b,
        b2Bcontact: this.tempContent,
        reviewed: this.course.reviewed,
      };
      // console.log(data);
      vm.$http.put(`${this.$API_PATH}/Admin/Course/Management/${this.id}`, data, {
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
          Authorization: `bearer ${vm.userAuth}`,
        },
      }).then((res) => {
        vm.goNext = true;
        Swal.fire(
          '儲存成功',
          `${res.data.message}`,
          'success',
        );
      }).catch((err) => {
        vm.$log.debug(err.response);
        if (err.response) {
          Swal.fire(
            '',
            `${err.response.data.message}`,
            'info',
          );
        } else {
          Swal.fire(
            '錯誤',
            '請稍候再試',
            'info',
          );
        }
      });
    },
    getCategories() {
      this.$http.get(`${this.$API_PATH}/Course/Categories`, {
        headers: {
          Accept: '*/*',
        },
      }).then((res) => {
        this.categories = res.data.data.courseCategories;
        this.$log.debug(this.categories);
      }).catch((err) => {
        if (err.response) {
          if (err.response.status == 401) {
            Swal.fire(
              '',
              '權限不足',
              'info',
            ).then((result) => {
              if (result.isConfirmed) {
                this.$router.push('/');
              }
            });
          }
        } else {
          Swal.fire(
            '錯誤',
            '請稍候再試',
            'info',
          ).then((result) => {
            if (result.isConfirmed) {
              this.$router.push('/');
            }
          });
        }
      });
    },
    getUploaded(e) {
      // this.$refs.img.style.backgroundImage = `url(${URL.createObjectURL(this.$refs.files.files[0])})`;
      const uploadedFile = this.$refs.files.files[0];
      this.$log.debug(uploadedFile);
      const vm = this;
      const formData = new FormData();
      formData.append('upload', uploadedFile);
      this.$http.post(`${vm.$API_IMGPATH}/Course`, formData)
        .then((response) => {
          vm.$log.debug(response.data);
          vm.coverImg = response.data.url;
          vm.$refs.img.style.backgroundImage = `url(${response.data.url})`;
        }).catch((err) => {
          Swal.fire(
            '錯誤',
            '請稍候再試',
            'info',
          );
          vm.$log.debug(err.response);
        });
    },
    getBookUploaded(e) {
      // this.$refs.img.style.backgroundImage = `url(${URL.createObjectURL(this.$refs.files.files[0])})`;
      const uploadedFile = this.$refs.bookfiles.files[0];
      this.$log.debug(uploadedFile);
      const vm = this;
      const formData = new FormData();
      formData.append('upload', uploadedFile);
      this.$http.post(`${vm.$API_IMGPATH}/CourseBook`, formData)
        .then((response) => {
          vm.$log.debug(response.data);
          vm.bookImg = response.data.url;
          vm.$refs.bookImg.src = `${response.data.url}`;
        }).catch((err) => {
          Swal.fire(
            '錯誤',
            '請稍候再試',
            'info',
          );
          vm.$log.debug(err.response);
        });
    },
    checkClick() {
      if (this.pattern.length == 0) {
        this.errorStr = '請選擇銷售模式';
      } else {
        this.errorStr = '';
      }
    },
    changePattern(str) {
      if (this.pattern.indexOf('b2b') == -1) {
        this.tempContent = '';
      }
    },
    handleKeydown() {
      if (!this.tags.includes(this.tag) && this.tag) {
        this.tag = this.tag.replace(/\s/g, '');
        this.tags.push(this.tag);
      }
      this.tag = '';
    },
    deleteTag(i) {
      this.tags.splice(i, 1);
    },
    deleteChannel(i) {
      this.channels.splice(i, 1);
    },
    getEditData() {
      this.updateLoading(true);
      this.$http.get(`${this.$API_PATH}/Admin/Course/Management/${this.id}`, {
        headers: {
          Accept: '*/*',
          Authorization: `bearer ${this.userAuth}`,
        },
      }).then((res) => {
        if (res.data.data) {
          // console.log(res.data);
          this.authorId = res.data.data.course.authorId;
          this.course = res.data.data.course;
          this.course.hashtags = res.data.data.hashtags;
          this.course.detail = this.decodeEntity(res.data.data.course.detail);
          // console.log(res.data.detail);
          this.tags = this.course.hashtags.map((item) => item.name);
          this.bookImg = this.course.prevImageUrl1 == 'NotSet' || '' ? 'NotSet' : this.course.prevImageUrl1;
          this.coverImg = this.course.prevImageUrl == 'NotSet' || '' ? 'NotSet' : this.course.prevImageUrl;
          if (this.course.isB2b) {
            this.pattern.push('b2b');
          }
          if (this.course.isB2c) {
            this.pattern.push('b2c');
          }
          this.tempContent = this.course.b2bcontact ? this.course.b2bcontact : '';
          this.updateLoading(false);
        }
        return this.$http.get(`${this.$API_PATH}/Admin/TeacherList`, {
          headers: {
            Accept: '*/*',
            Authorization: `bearer ${this.userAuth}`,
          },
        });
      }).then((res) => {
        let result = '';
        this.teacherList = res.data.data.teacherList;
        res.data.data.teacherList.forEach((item) => {
          if (item.id == this.authorId) {
            result = item.userName;
          }
        });
        this.authorName = result;
      }).catch((err) => {
        this.goNext = true;
        this.updateLoading(false);
        if (err.response) {
          if (err.response.status == 404) {
            Swal.fire(
              '',
              '找不到該課程',
              'info',
            ).then((result) => {
              if (result.isConfirmed) {
                this.$router.push('/');
              }
            });
          }
        } else {
          Swal.fire(
            '錯誤',
            '請稍候再試',
            'info',
          ).then((result) => {
            if (result.isConfirmed) {
              this.$router.push('/');
            }
          });
        }
      });
    },
    backToMyCourse() {
      this.$router.push('/dashboard/courses');
    },
    decodeEntity(inputStr) {
      const textarea = document.createElement('textarea');
      textarea.innerHTML = inputStr;
      return textarea.value;
    },
    getAuthor() {
      this.$http.get(`${this.$API_PATH}/Admin/Course/Management`, {
        headers: {
          Accept: '*/*',
          Authorization: `bearer ${this.userAuth}`,
        },
      }).then((res) => {
        this.authorId = res.data.data.courseList.filter((item) => item.id == this.id)[0].authorId;
        return this.$http.get(`${this.$API_PATH}/Admin/TeacherList`, {
          headers: {
            Accept: '*/*',
            Authorization: `bearer ${this.userAuth}`,
          },
        });
      }).then((res) => {
        let result = '';
        this.teacherList = res.data.data.teacherList;
        res.data.data.teacherList.forEach((item) => {
          if (item.id == this.authorId) {
            result = item.userName;
          }
        });
        this.authorName = result;
      }).catch((err) => {
        this.goNext = true;
        if (err.response) {
          if (err.response.status == 401) {
            Swal.fire(
              '',
              '權限不足',
              'info',
            ).then((result) => {
              if (result.isConfirmed) {
                this.$router.push('/');
              }
            });
          }
        } else {
          Swal.fire(
            '錯誤',
            '請稍候再試',
            'info',
          ).then((result) => {
            if (result.isConfirmed) {
              this.$router.push('/');
            }
          });
        }
      });
    
    },
    getChapter() {
      this.$http.get(`${this.$API_PATH}/Course?CourseId=${this.id}`, {
        headers: {
          Accept: '*/*',
          Authorization: `bearer ${this.userAuth}`,
        },
      }).then((res) => {
        this.chapters = Array.from(res.data.data.chapter);
      })
    },
    formatTime(num) {
      return num < 10 ? `0${num}` : num;
    },
    pauseVideo() {
      this.$refs.player.pause();
    },
    linkify() {
      let replacedText;
      if(this.addedLinkText !== '') {
        replacedText = `<a href="${this.addedLink}" target="_blank">${this.addedLinkText}</a>`;
      } else {
        replacedText = `<a href="${this.addedLink}" target="_blank">${this.addedLink}</a>`;
      }
      this.linkString = replacedText;
    },
    getFilesUpload() {
      this.$http.get(`${this.$API_PATH}/Course/FileUpload/${this.id}`, {
        headers: {
          Accept: '*/*',
          Authorization: `bearer ${this.userAuth}`,
        },
      }).then((res) => {
        if (res.data.data) {
          this.sampleFiles = res.data.data.files;
        }
      }).catch((err) => {
        if (err.response) {
          Swal.fire(
            '',
            `${err.response.data.message}`,
            'info',
          );
        } else {
          Swal.fire(
            '',
            '錯誤，請稍後再試',
            'info',
          );
        }
      vm.$log.debug(err.response);
      });
    },
    submitChannel() {
      this.channels.push({
        id: Math.floor(Date.now()),
        name: this.nowChannelName,
        num: this.nowChannelNum
      });
      this.nowChannelName = '';
      this.nowChannelNum = '';
      console.log(this.channels);
    },
    finishChannel(item) {
      let i;
      this.channels.forEach((el, idx) => {
        if (el.salesChannelId == item.salesChannelId) {
          i = idx;
        }
      });
      this.channels[i] = this.tempChannel;
    },
    editChannel(item) {
      this.tempChannel = item;
    },
    getChannelData() {
      this.$http.get(`${this.$API_PATH}/Admin/Course/ProductSN/${this.id}`, {
        headers: {
          Accept: '*/*',
          Authorization: `bearer ${this.userAuth}`,
        },
      }).then((res) => {
        console.log(res.data.data);
        this.channels = [...res.data.data];
        this.testChannels = JSON.parse(JSON.stringify(res.data.data));
      }).catch((err) => {
        if (err.response) {
          Swal.fire(
            '',
            `${err.response.data.message}`,
            'info',
          );
        } else {
          Swal.fire(
            '',
            '錯誤，請稍後再試',
            'info',
          );
        }
        vm.$log.debug(err.response);
      });
    },
    updateChannelData() {
      this.$http.put(`${this.$API_PATH}/Admin/Course/ProductSN/${this.id}`,
        { courseSalesChannelProductSNs: this.channels },
        {
        headers: {
          Accept: '*/*',
          Authorization: `bearer ${this.userAuth}`,
        },
      }).then((res) => {
        console.log(res.data.data);
        this.channels = [...res.data.data];
        this.testChannels = JSON.parse(JSON.stringify(res.data.data));
        Swal.fire(
          '修改成功',
          '',
          'info',
        );
      }).catch((err) => {
        if (err.response) {
          Swal.fire(
            '',
            `${err.response.data.message}`,
            'info',
          );
        } else {
          Swal.fire(
            '',
            '錯誤，請稍後再試',
            'info',
          );
        }
        vm.$log.debug(err.response);
      });
    },
    ...mapActions(['getUserAuth', 'getUserAuthFromLocal', 'checkExpAuth', 'updateLoading']),
  },
  computed: {
    showChannelEditor() {
      let flag = false;
      this.testChannels.forEach((item) => {
        if(item.productSN !== '') {
          flag = true;
        }
      });
      return flag
    },
    ...mapGetters(['userName', 'Avatar', 'userId', 'role', 'exp', 'userAuth', 'LoginProvider']),
  },
  created() {
    this.getUserAuthFromLocal();
    this.checkExpAuth();
    if (this.userAuth.length == 0) {
      this.$router.push('/login');
    }
    this.getCategories();
    this.id = this.$route.params.id;
    // this.getAuthor();
    this.getEditData();
    this.getChapter();
    this.getFilesUpload();
    this.getChannelData();
  },
};
</script>

<style lang="scss" scoped>
.invalid .text-danger {
    text-align: left;
    width: 100%;
    display: block;
    font-size: 12px;
  }

  .invalid input {
    border: 1px #EB0600 solid;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(252, 67, 21, 0.25);
    }
  }

  .invalid.text-danger {
    text-align: left;
    width: 100%;
    display: block;
    font-size: 12px;
  }

  .btn-check:checked + .btn-main-outline {
    background-color: #012970;
    color: white;
  }
  ::v-deep .quill-editor {
    padding: 12px 0px;
    height: 200px;
  }

  .form-control[readonly] {
    background-color: white;
  }
</style>
